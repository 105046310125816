@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&family=Send+Flowers:wght@400&display=swap");

/* html,
body {
  height: 100%;
} */

body {
  /* overflow: hidden; */
  display: flex;
}

/* #root {
  display: flex;
  height: 100%;
} */

html {
  height: -webkit-fill-available;
}

#root {
  flex: 1;
}
